








































































































































import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Profile extends Vue {
  form = {
    Name: "",
    ShortName: "",
    Nric: "",
    TelNo: "",
    Email: "",
    LorryPlateNumber: "",
    LorryTypeName: "",
  };
  tripSummary = {
    tenWheels: {
      name: "",
      doCount: 0,
      levels: [] as Array<{
        name: string;
        min: number;
        max: number;
        point: number;
        count: number;
      }>,
    },
    twelveWheels: {
      name: "",
      doCount: 0,
      levels: [] as Array<{
        name: string;
        min: number;
        max: number;
        point: number;
        count: number;
      }>,
    },
  };
  loading = false;

  async created() {
    await this.$store.dispatch({
      type: "driver/profile",
    });
    this.form = this.$store.state.driver.profile;

    await this.$store.dispatch({
      type: "driver/trip",
    });
    const tenWheels = this.$store.state.driver.trip.find(
      (x) => x.LorryTypeId == 9
    );
    const twelveWheels = this.$store.state.driver.trip.find(
      (x) => x.LorryTypeId == 10
    );

    this.tripSummary.tenWheels = {
      name: tenWheels.LorryTypeName,
      doCount: tenWheels.DriverDOCount,
      levels: tenWheels.Levels.map((l) => {
        return {
          name: l.LevelName,
          min: l.Min,
          max: l.Max,
          point: l.Point,
          count: l.TripCount,
        };
      }),
    };
    this.tripSummary.twelveWheels = {
      name: twelveWheels.LorryTypeName,
      doCount: twelveWheels.DriverDOCount,
      levels: twelveWheels.Levels.map((l) => {
        return {
          name: l.LevelName,
          min: l.Min,
          max: l.Max,
          point: l.Point,
          count: l.TripCount,
        };
      }),
    };
  }
}
